import {
  timeManager,
  CustomEvents,
  corePhasesManager,
  playersManager,
  gsap,
  modes,
  TimesTypes,
  fpsManager,
  minigameConfig,
  game,
  type PlayerInfo,
  cameraManager,
  CameraStates,
  audioManager,
  type TournamentDataFromResultsRequest
} from '@powerplay/core-minigames'
import {
  DisciplinePhases,
  type StartPositionsDataObject,
  AudioGroups
} from '../types'
import { StartPhaseManager } from './StartPhase/StartPhase'
import { FinishPhaseManager } from './FinishPhase/FinishPhase'
import { player } from '../entities/athlete/player'
import { RunningPhase } from './RunningPhase/RunningPhase'
import { endManager } from '../EndManager'
import { opponentsManager } from '../entities/athlete/opponent/OpponentsManager'
import { gameConfig } from '../config'
import {
  tableState,
  trainingResultsState,
  uiState
} from '@/stores'
import { waitingState } from '@powerplay/core-minigames-ui-ssm'
import { stateManager } from '../StateManager'

/**
 * Trieda pre spravu faz
 */
export class DisciplinePhasesManager {

  /** aktualna faza */
  public actualPhase = 0

  /** pokus */
  public attempt = 1

  /** tween na nastartovanie fazoveho managera */
  private startDisciplineTween!: gsap.core.Tween

  /** hlasitost pre divakov */
  public audienceSoundVolume = { value: 1 }

  /** Data pre startovacie pozicie */
  public dataForPlayersStartPositions: StartPositionsDataObject = {}

  /** Faza startu */
  public phaseStart!: StartPhaseManager

  /** Faza behu */
  public phaseRunning!: RunningPhase

  /** faza konca */
  public phaseFinish!: FinishPhaseManager

  /**
   * Vytvorenie a nastavenie veci
   */
  public create(): void {

    this.createAllPhases()

  }

  /**
   * Vytvorenie menegerov faz
   */
  public createAllPhases(): void {

    this.phaseStart = new StartPhaseManager(() => {

      this.startDisciplinePhase(DisciplinePhases.running)
      timeManager.setActive(TimesTypes.game, true)

    })

    this.phaseRunning = new RunningPhase(() => {

      this.startDisciplinePhase(DisciplinePhases.finish)

    })

    this.phaseFinish = new FinishPhaseManager(() => {

      this.actualPhase++
      console.log('dispatch end')
      if (DisciplinePhases[this.actualPhase]) {

        console.log('dispatch end')
        window.dispatchEvent(new CustomEvent(CustomEvents.finishDisciplinePhase))

        waitingState().isWaiting = true

      }

    })

  }

  /**
   * Zistenie, ci jedna z faza je aktualna faza
   * @param phase - Pole faz na skontrolovanie
   * @returns True, ak je jedna z faz aktualna
   */
  public oneOfPhaseIsActual(phases: DisciplinePhases[]): boolean {

    return phases.includes(this.actualPhase)

  }

  public getActualPhase(): DisciplinePhases {

    return this.actualPhase

  }

  /**
   * Spustenie fazy
   * @param phase - Cislo fazy
   */
  public startDisciplinePhase(phase: DisciplinePhases): void {

    this.actualPhase = phase

    if (phase === DisciplinePhases.start) this.phaseStart.startPhase()
    if (phase === DisciplinePhases.running) this.phaseRunning.startPhase()
    if (phase === DisciplinePhases.finish) this.phaseFinish.startPhase()

  }

  /**
   * Update aktualnej fazy kazdy frame
   */
  public update(): void {

    this.updateAudienceSound()

    if (this.actualPhase === DisciplinePhases.start) this.phaseStart.update()
    if (this.actualPhase === DisciplinePhases.running) this.phaseRunning.update()
    if (this.actualPhase === DisciplinePhases.finish) this.phaseFinish.update()

  }

  /**
   * zmen hlasitost divakov podla vzdialenosti
   */
  private updateAudienceSound(): void {

    // audioManager.changeAudioVolume(AudioNames.audienceNoise, this.audienceSoundVolume.value)

  }

  /**
   * rekurzivne ukoncime vsetky fazy
   */
  public disciplinePrematureEnd = () => {

    this.actualPhase = DisciplinePhases.end

    corePhasesManager.disciplineActualAttempt = corePhasesManager.disciplineAttemptsCount
    // kvoli dennej lige musime dat naspat originalnych superov, aby sa zobrazili v konecnej listine
    if (modes.isDailyLeague()) opponentsManager.setOriginalData()

    playersManager.setStandings()
    console.log('STANDINGS', playersManager.getStandings())

    fpsManager.pauseCounting()

    const isFinished = false

    // posleme udaje
    endManager.sendLogEnd()
    endManager.sendSaveResult()

    // reset states
    stateManager.resetPinia(modes.isTournament() ? ['waitingState', 'blurState', 'tableState'] : [])

    waitingState().isWaiting = true
    tableState().$patch({
      showTable: true,
      activeState: false,
      dataTable: [],
      isStartList: false,
    })

    if ((!isFinished || corePhasesManager.firstInstructions) && !modes.isTrainingMode()) {

      trainingResultsState().isDisabledPlayAgain = true

    }
    // stopneme vsetky animacne callbacky
    if (player.animationsManager) player.animationsManager.removeCallbacksFromAllAnimations()

  }

  /**
   * Nastartovanie disciplinoveho fazoveho managera
   */
  public setStartPhase = (): void => {

    // v treningu musime spravit nejake upravy, aby vsetko fungovalo ako malo
    if (modes.isTrainingMode()) {

      player.updateBeforePhysics(disciplinePhasesManager.actualPhase)
      player.updateCameraConfigOnStart(CameraStates.intro)
      if (gameConfig.cameraConfig.enabled) player.setGameCameraSettings()
      cameraManager.setState(CameraStates.intro)
      cameraManager.playTween(false, this.phaseStart.secondCameraDisciplineIntro)

    }

    if (modes.isTutorial() && corePhasesManager.disciplineActualAttempt === 1) {

      this.startStartPhase()
      return

    }

    // musime tu dat mensi delay, lebo mozeme skipovat este nejake fazy predtym
    this.startDisciplineTween = gsap.to({}, {
      duration: modes.isTutorial() ? 0.01 : 0.01,
      onComplete: () => {

        this.startStartPhase()

      }
    })

  }

  /**
   * Spustenie start phase
   */
  private startStartPhase(): void {

    const phase = DisciplinePhases.start
    this.startDisciplinePhase(phase)

  }

  /**
   * resetovanie hry
   * @param resetAttempts - Ci ma byt tvrdy reset aj s poctom pokusov
   */
  public resetAttempt(resetAttempts = false): void {

    if (resetAttempts) {

      this.attempt = 0

    }
    if (this.attempt >= 2 && !modes.isTutorial()) {

      endManager.wasDsq = true
      playersManager.setPlayerResults(minigameConfig.dsqValue)
      game.prematureFinishGame(disciplinePhasesManager.disciplinePrematureEnd)
      return

    }

    this.attempt += 1


    this.actualPhase = 0

    player.athleteAnimationManager.skipStartRun()
    opponentsManager.skipStartRun()

    audioManager.stopAudioByGroup(AudioGroups.audience)

    uiState().showWind = false
    timeManager.reset()
    console.log('reseting')
    player.reset()
    opponentsManager.reset()

    if (resetAttempts) return
    this.startDisciplinePhase(DisciplinePhases.start)

  }

  /**
   * Nastavenie startovacich pozicii pre vsetkych hracov
   */
  public setStartPositionsForPlayers(): void {

    // musime si presunut data do noveho pola, aby sa neprepisovali hodnoty z packages
    let players: PlayerInfo[] = []
    players.push(...playersManager.players.map((val) => val))

    // v dennej lige je nahodne poradie
    if (modes.isDailyLeague() || modes.isTournament()) {

      const shuffle = (array: PlayerInfo[]) => {

        for (let i = array.length - 1; i > 0; i--) {

          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]]

        }
        return array

      }

      const { numberOfOpponents } = gameConfig
      players = shuffle(players.filter((_playerInfo: PlayerInfo, index: number) => index <= numberOfOpponents))

    } else if (modes.isBossCompetition() || modes.isEventBossFight()) {

      players.sort((a: PlayerInfo, b: PlayerInfo): number => {

        if (a.attribute.total === undefined || b.attribute.total === undefined) return -1

        return b.attribute.total - a.attribute.total

      })

    } else {

      // zoradime si hracov podla RP a prejdeme si vsetkych hracov a podla uuid nastavime pole
      players.sort((a: PlayerInfo, b: PlayerInfo): number => {

        if (a.rankingPoints === undefined || b.rankingPoints === undefined) return -1

        return b.rankingPoints - a.rankingPoints

      })

    }

    const pathIndexes = [5, 4, 6, 3, 7, 2, 8, 1]

    players.forEach((playerInfo: PlayerInfo, index: number) => {

      this.dataForPlayersStartPositions[playerInfo.uuid] = pathIndexes[index]

    })

  }

  /**
   * Nastavenie dat o hracoch
   * @param dataCallback - funkcia na ukladanie
   */
  public setOpponentsForFinishTable(dataCallback: TournamentDataFromResultsRequest): void {

    opponentsManager.setOpponentsDataFromSaveResults(dataCallback as TournamentDataFromResultsRequest)
    this.phaseFinish.dailyLeagueSetResultsOpponentsFreeze = true
    this.phaseFinish.prepareFinishTable()
    waitingState().isWaiting = false
    tableState().activeState = true

  }

  /**
   * Reinstancovanie manazerov
   */
  public reset(): void {

    this.dataForPlayersStartPositions = {}
    this.setStartPositionsForPlayers()
    this.createAllPhases()
    this.resetAttempt(true)
    endManager.prematureEnded = false

  }

}

export const disciplinePhasesManager = new DisciplinePhasesManager()
